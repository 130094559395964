import { ICertificate } from './../Certificate/index'
import { IBaseReducer } from '../BaseReducer'
import { IBaseGetParams, IError } from '../Global'
import { IRegion } from '../Region'
import { IRoleData } from '../Auth'
import { IUserCertificate } from '../UserCertificate'
import { IBlock } from '../Block'
import { IBusinessEntity, IPutBusinessEntityParams } from '../BusinessEntity'
import { IStrike } from '../Strike'
import { IReview } from '../Review'
import { IShift } from '../Shift'
import { IFavorite } from '../Favorite'
import { IDeduction } from '../Deduction'
import { TypeOrNull } from '../Common'
import { EXPERIENCE_TYPE, IExperience } from '../Experience'
import { IPointOfContact } from '../PointOfContact'
import { IUserPointOfContact } from '../UserPointOfContact'
import { VACCINATION_STATUS, IVaccinationForm } from '../VaccinationForm'
import { PHONE_TYPE } from 'models/PhoneType'
import { PAYMENT_TYPE_ID } from 'helpers/constants/constants'
import { IPunchCard } from 'models/PunchCard'

export enum LAST_WORKED {
  NEVER,
  UP_TO_21_DAYS,
  UP_TO_59_DAYS,
  MORE_THAN_60_DAYS,
}

export enum USER_ROLE {
  BUSINESS = 'business',
  EMPLOYEE = 'employee',
  FINANCE_MANAGER = 'finance_manager',
  UPSHIFTER = 'upshifter',
  LABOR = 'labor',
  APPROVER = 'approver',
  VIEWER = 'viewer',
  // Admin Roles
  ADMIN = 'admin',
  ONBOARDER = 'onboarder',
  SALES = 'sales',
  ACCOUNT_MANAGER_LEVEL_1 = 'account_manager_level_one',
  ACCOUNT_MANAGER_LEVEL_2 = 'account_manager_level_two',
}

export enum USER_STATUS {
  PERMANENTLY_SUSPENDED,
  TEMPORARY_SUSPENDED,
  ACTIVE,
  PENDING,
  PENDING_SUSPENSION,
}

export enum USER_LAST_WORKED {
  LAST_12_MONTHS = 1,
  LAST_3_MONTHS = 2,
}

export enum BANK_INFO_STATUS {
  PENDING,
  APPROVED,
  DENIED,
  NONE,
}

export enum MY_UPSHIFTERS_FILTER {
  ALL_UPSHIFTERS,
  FAVORITE_UPSHIFTERS,
  BLOCKED_UPSHIFTERS,
}

/**
 * Data for include: point_of_contacts.point_of_contact.
 * include: point_of_contacts will return only IUserPointOfContact only, without point_of_contact field described in this interface.
 */
export interface IPointOfContactData extends IUserPointOfContact {
  point_of_contact: IPointOfContact
}

export interface IUserIncludeParams {
  include?:
    | {
        finished_punch_cards_sum_hours_total?: {
          business_id?: number
        }
        business?: Record<any, any>
        favorited_by_business?: {
          business_id?: number
        }
        finished_gigs_count?: {
          business_id?: number
        }
        finished_punch_cards_last_year_sum_hours_total?: {
          business_id?: number
        }
        last_completed_punch_card?: Record<any, any>
        managed_businesses_count?: Record<any, any>
      }
    | (
        | 'finished_punch_cards_sum_hours_total'
        | 'business'
        | 'favorited_by_business'
        | 'finished_gigs_count'
        | 'point_of_contacts.point_of_contact'
        | 'finished_punch_cards_last_year_sum_hours_total'
        | 'last_completed_punch_card'
        | 'managed_businesses_count'
      )[]
}

export interface IUser {
  id: number
  uuid: string
  email: string
  full_name: string
  first_name: string
  last_name: string
  zip_code: string
  phone: string
  phone_formatted?: string
  phone_type: TypeOrNull<PHONE_TYPE>
  description: string
  description_rich_text: string
  timezone: string
  avatar_url: string
  avatar_thumb_url: string
  date_of_birth: Date
  age: number
  rating: string
  confirmed: boolean
  status: USER_STATUS
  check_candidate_id: number
  employer_id: number
  employer_business_id: number
  ec_full_name: string
  ec_phone: string
  ec_relationship: string
  created_at: string
  password_last_changed_at: string
  changed_password: boolean
  new_app: boolean
  overdue: boolean
  site: boolean
  role: USER_ROLE
  roles: IRoleData[]
  regions: IRegion[]
  can_login: boolean
  company_name: string
  payment_type_id: PAYMENT_TYPE_ID
  apc_email: string
  apc_phone: string
  apc_full_name: string
  sales_agent_id: number
  account_manager_id: number
  timetracker: boolean
  favorite: boolean
  stats: IUserStats
  ssn_last_four: string
  employee_id: string
  employer_business_od: number
  has_background_check_certificate: number
  has_requested_background_check: number
  approved_at: Date
  will_not_pass_background_check: boolean
  has_required_docs: boolean
  bank_info_status: BANK_INFO_STATUS
  bank_routing_number: string
  bank_account_number: string
  ssn_last_four_enabled: boolean
  upshifter_punch_card_updates_enabled: boolean
  tfa_status: boolean
  point_of_contacts?: {
    data: IPointOfContactData[]
  }
  vaccination_status: VACCINATION_STATUS
  title: string
  suspended_at: string
  suspended_until: string
  show_review_app: boolean
  gamification_enabled: boolean
  can_view_strikes?: boolean
  can_revoke_strikes?: boolean
  finished_punch_cards_sum_hours_total?: number
  finished_punch_cards_last_year_sum_hours_total?: number
  last_completed_punch_card?: IPunchCard
  managed_businesses_count?: number
  blocked_properties_count?: number
  vms_marriott_id: TypeOrNull<number>

  /**
   * Start Deprecated
   */

  hours_worked: number
  business_id: number
  favorite_id: number | null
  finished_gigs_count: number

  /**
   * End Deprecated
   */
}

export interface IGetUsersParamsBase extends IBaseGetParams {
  uuid?: string
  last_name?: string
  date_of_birth?: Date
  ssn_last_four?: string
  certificate_ids?: number[]
  role?: USER_ROLE
  roles?: USER_ROLE[]
  role_id?: number
  regions?: number[]
  properties?: number[]
  property_assigned?: boolean
  block?: boolean
  worked_for_before_id?: number
  worked_for_id?: number
  worked_for_business_id?: number
  favorite_business_id?: number
  business_id?: number
  favored?: boolean
  block_owner_id?: number
  block_business_id?: number
  block_user_id?: number
  sales_agent_id?: number
  account_manager_id?: number
  status?: USER_STATUS
  statuses?: USER_STATUS[]
  payment_type_id?: number
  order_by?: string
  site?: boolean
  employer_id?: number
  employer_business_id?: number
  worked_for_industry_ids?: number[]
  worked_for_property_ids?: number[]
  worked_for_business_ids?: number[]
  point_of_contact_ids?: number[]
  point_of_contact_names?: string[]
  vaccination_status?: VACCINATION_STATUS
  experience_type?: EXPERIENCE_TYPE
  last_worked?: LAST_WORKED[]
  my_upshifters?: MY_UPSHIFTERS_FILTER
  worked_positions?: number[]
  is_account_manager_to_business?: boolean
  is_sales_manager_to_business?: boolean
}

export type IGetUsersParams = IGetUsersParamsBase & IUserIncludeParams

export interface IGetUserParamsBase {
  business_id?: number
  favorite_business_id?: number
  worked_for_business_id?: number
}

export type IGetUserParams = IGetUserParamsBase & IUserIncludeParams

export interface IPostUserParamsBase {
  title?: string
  first_name: string
  last_name: string
  password?: string
  password_confirmation?: string
  email?: string
  role: string
  avatar?: string
  date_of_birth?: Date
  ssn_last_four?: string
  phone?: string
  notification_token?: string
  payment_type_id?: PAYMENT_TYPE_ID
  regions?: number[]
  properties?: number[]
  assign?: boolean
  timetracker_clock_in_only?: boolean
  status?: USER_STATUS
  checkr_candidate_id?: number
  employer_id?: number
  employer_business_id?: number
  site?: boolean
  will_not_pass_background_check?: boolean
  can_login?: boolean
  ssn_last_four_enabled?: boolean
  tfa_status?: boolean
  business_entity_data?: IPutBusinessEntityParams
}

export type IPostUserParams = IPostUserParamsBase & IUserIncludeParams

export interface IPutUserParamsBase {
  title?: string
  first_name?: string
  last_name?: string
  password?: string
  email?: string
  role?: string
  date_of_birth?: Date
  ssn_last_four?: string
  phone?: string
  notification_token?: string
  payment_type_id?: PAYMENT_TYPE_ID
  regions?: number[]
  properties?: number[]
  assign?: boolean
  timetracker_clock_in_only?: boolean
  overdue?: boolean
  status?: USER_STATUS
  checkr_candidate_id?: number
  site?: boolean
  description?: string
  employee_id?: string
  vms_marriott_id?: TypeOrNull<string>
  will_not_pass_background_check?: boolean
  bank_routing_number?: string
  bank_account_number?: string
  employer_business_id?: number
  ssn_last_four_enabled?: boolean
  upshifter_punch_card_updates_enabled?: boolean
  tfa_status?: boolean
  show_review_app?: boolean
  gamification_enabled?: boolean
  can_view_strikes?: boolean
  can_revoke_strikes?: boolean
}

export type IPutUserParams = IPutUserParamsBase & IUserIncludeParams
export interface IUpdateUserAvatarParams {
  avatar: Blob
}

export interface IRole {
  id: number
  name: string
}

interface IUserStats {
  notifications_count: number
  active_punch_cards_count2: number
  active_punch_cards_count: number
  active_strikes_count: number
  expired_strikes_count: number
  pending_gigs_count: number
  accepted_gigs_count: number
  confirmed_gigs_count: number
  active_gigs_count: number
  finished_gigs_count: number
  total_finished_gigs_count: number
  finished_punch_cards_count: number
  hours_worked: string
  total_hours_worked: string
  pay_stub_count: number
  last_worked_punch_card_timestamp_start?: string
  is_eligible_for_early_access?: boolean
  is_unreliable?: boolean
}

export interface ISickPayDayHour {
  state_id?: number
  city_id?: number
  county_id?: number
  name: string
  total_hours: string
  accrual_rate: string
  yearly_hours: string
  pending_hours: string
  accrued_hours: string
  used_hours: string
  total_worked_hours: string
  yearly_worked_hours: string
}

export interface IUserSickPayDayHourStats {
  cities: ISickPayDayHour[]
  counties: ISickPayDayHour[]
  states: ISickPayDayHour[]
}

export interface IUsersReducer extends IBaseReducer<IUser[]> {
  isFavoriteButtonLoadingIds: number[]
  isApproveButtonLoadingIds: number[]
  isActivateSuspendButtonLoadingIds: {
    [key: number]: boolean
  }
}

export interface IUserReducer extends IBaseReducer<IUser> {}

interface IUpshifterReviewReducer extends IBaseReducer<IReview[]> {}
interface IUpshifterShiftsReducer extends IBaseReducer<IShift[]> {}
interface IUpshifterFavoriteReducer extends IBaseReducer<IFavorite[]> {
  favoriteLoadingIds: number[]
}
interface IUpshifterBlocksReducer extends IBaseReducer<IBlock[] | IBusinessEntity[]> {
  blocksLoadingIds: number[]
  shouldDataRefresh: boolean
}
interface IUpshifterStrikesReducer extends IBaseReducer<IStrike[]> {
  activeStrikesTotal: number
  isFetchingActiveStrikes: boolean
  activeStrikesError: TypeOrNull<IError>
}
interface IUpshifterAssignedCertificatesReducer extends IBaseReducer<IUserCertificate[]> {
  assignButtonLoadingIds: number[]
}
interface IUpshifterUnassignedCertificatesReducer extends IBaseReducer<ICertificate[]> {
  unassignButtonLoadingIds: number[]
}

interface IUpshifterDeductionsReducer extends IBaseReducer<IDeduction[]> {}

export interface IUpshifterExperienceReducer extends IBaseReducer<IExperience[]> {}

interface IUpshifterVaccinationFormsReducer extends IBaseReducer<IVaccinationForm[]> {}

export interface IUpshifterReducer extends IBaseReducer<IUser> {
  isUpshifterUpdating: boolean
  strikes: IUpshifterStrikesReducer
  blocks: IUpshifterBlocksReducer
  reviews: IUpshifterReviewReducer
  shifts: IUpshifterShiftsReducer
  favorites: IUpshifterFavoriteReducer
  assignedCertificates: IUpshifterAssignedCertificatesReducer
  unassignedCertificates: IUpshifterUnassignedCertificatesReducer
  deductions: IUpshifterDeductionsReducer
  experiences: IUpshifterExperienceReducer
  vaccinations: IUpshifterVaccinationFormsReducer
}

export interface IUserSickPayDayHoursReducer extends IBaseReducer<ISickPayDayHour[]> {}
