import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import { IGetGroupMembersParams, IGroupMember } from 'models/GroupMember'

export class GroupMembersApi {
  constructor(private axios: AxiosService) {}

  getGroupMembers = async (params?: IGetGroupMembersParams) => {
    const response = await this.axios.get<IPayload<IGroupMember[]>>(
      Endpoints.getGroupMembers,
      params
    )
    return response
  }
}
