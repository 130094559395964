import { IBaseReducer } from '../BaseReducer'
import { IDropdownData, TypeOrNull } from '../Common'
import { IBaseGetParams } from '../Global'
import { IGroupApplication } from 'models/GroupApplication'
import { IGigDay } from 'models/GigDay'
import { BACKGROUND_CHECK_STATUS } from 'models/BackgroundCheck'
import { USER_STATUS } from 'models/User'

export enum APPLICATION_STATUS {
  PENDING = 0,
  ACCEPTED = 1,
  DECLINED = 2,
  UPSHIFTER_CANCELED = 3,
  ON_HOLD = 4,
  OVERTIME = 5,
  PENDING_SCREENING = 7,
  HIDDEN = 8,
}

export enum APPLICATION_ORDER_BY {
  MOST_RELEVANT = '',
  MOST_RECENT_APPLICATION = 'most_recent',
  HOURS_WORKED_TOTAL = 'total_hours_worked',
}

export enum BANK_INFO_STATUS {
  PENDING,
  APPROVED,
  DENIED,
  NONE,
}

export interface IApplicant {
  id: number
  uuid: string
  full_name: string
  first_name: string
  last_name: string
  avatar_thumb_url: string
  age: number
  approved_at: any
  avatar_url: string
  background_check_status: BACKGROUND_CHECK_STATUS
  bank_account_number: any
  bank_info_status: BANK_INFO_STATUS
  bank_routing_number: any
  can_login: boolean
  changed_password: boolean
  checkr_candidate_id: string
  confirmed: boolean
  created_at: any
  date_of_birth: any
  description: string
  document_category_a_id: any
  document_category_b_id: any
  document_category_c_id: any
  ec_full_name: any
  ec_phone: any
  ec_relationship: any
  email: string
  employee_id: number
  employer_business_id: number
  favorite: boolean
  favorite_id: TypeOrNull<number>
  has_background_check_certificate: number
  has_requested_background_check: number
  has_required_docs: any
  last_login_at: any
  new_app: boolean
  password_last_changed_at: any
  phone: string
  phone_type: string
  rating: any
  regions: any[]
  roles: any[]
  show_review_app: boolean
  ssn_last_four: string
  stats: {
    notifications_count: number
    active_punch_cards_count2: number
    active_punch_cards_count: number
    accepted_gigs_count: number
    active_gigs_count: number
    active_strikes_count: number
    confirmed_gigs_count: number
    expired_strikes_count: number
    finished_gigs_count: number
    finished_punch_cards_30_days_count: number
    finished_punch_cards_count: number
    hours_worked: number
    pending_gigs_count: number
    total_finished_gigs_count: number
    total_hours_worked: number
    is_eligible_for_early_access?: boolean
    is_unreliable?: boolean
  }
  status: USER_STATUS
  tfa_status: boolean
  timezone: string
  went_to_review_app: boolean
  went_to_review_app_type: any
  will_not_pass_background_check: boolean
  zip_code: string
}

export interface IApplication {
  id: number
  gig_id: number
  applicant: IApplicant
  confirmed: boolean
  accepted_beforehand: number
  hard_cancel: boolean
  clocked_in: boolean
  active_punch_card_id: number
  on_deck: boolean
  status: APPLICATION_STATUS
  review_id: number
  review_automatically: boolean
  actions: []
  active_background_check_without_consent: any
  auto_accept: boolean
  canceled_days: any
  blocks_count?: number
  group_id?: number
  group_application?: IGroupApplication
  block_properties_count?: number
  first_accepted_working_day?: IGigDay
  all_not_declined_punch_cards_count?: number
  is_hidden_from_business?: boolean
  all_active_punch_cards_count?: number
  finished_full_punch_cards_count?: number
}

export interface IApplicationsIncludeParams {
  include?:
    | {
        blocks_count?: {
          business_id?: number
        }
        block_properties_count?: {
          property_id: number
        }
        finished_full_punch_cards_count?: {
          gig_type_category_ids: number[]
        }
        first_accepted_working_day?: Record<any, any>
        all_not_declined_punch_cards_count?: Record<any, any>
        all_active_punch_cards_count?: Record<any, any>
      }
    | (
        | 'blocks_count'
        | 'block_properties_count'
        | 'finished_full_punch_cards_count'
        | 'first_accepted_working_day'
        | 'all_not_declined_punch_cards_count'
        | 'all_active_punch_cards_count'
      )[]
}

export interface IGetApplicationsParamsBase extends IBaseGetParams {
  gig_id?: number
  status?: APPLICATION_STATUS
  worked_for_business_id?: number
  favorite_business_id?: number
  order_by?: APPLICATION_ORDER_BY
  confirmed?: boolean
  show_group_applications?: boolean
  one_application_per_group?: number
}

export type IGetApplicationsParams = IGetApplicationsParamsBase & IApplicationsIncludeParams

export interface IPostApplicationParams extends IApplicationsIncludeParams {
  user_id: number
  gig_id: number
  status?: APPLICATION_STATUS
  confirmed?: boolean
  skip_one_business_overtime_validation?: boolean
  skip_multiple_business_overtime_validation?: boolean
  assign_screening_requirement?: boolean
  skip_will_not_pass_background_check?: boolean
  skip_training_mode_validation?: boolean
  skip_acknowledged_docs_check?: boolean
  show_group_applications?: boolean
  skip_restrict_last_minute_additions?: boolean
}

export interface ICancelledGigDay {
  canceled_at: string
  day_id: number
  hard_cancel: boolean
}

export interface IPutApplicationParams {
  confirmed?: boolean
  status?: APPLICATION_STATUS
  skip_one_business_overtime_validation?: boolean
  skip_restrict_last_minute_additions?: boolean
}

export interface IGetConfirmedApplicationsPdfParams {
  gig_id: number
}

export interface ISelectedShiftApplicationsReducer extends IBaseReducer<IApplication[]> {}

export interface IApplicantDropdownData extends IDropdownData {
  application_id: number
}
