import { NODE_ENV } from 'config/envVariables'
import { BONUS_CATEGORY } from 'models/Bonus'
import { USER_ROLE } from 'models/User'

// TODO(FND-5247): Move TS interfaces and other helpers from the constants file to their respective models file

export enum PAYMENT_TYPE_ID {
  ZERO,
  LOW_CREDIT_CARD,
  NET,
}

export enum SHIFT_FILLED {
  NOT_FILLED,
  FILLED,
}

export enum SHIFT_TO_HIRE {
  DISABLED,
  ENABLED,
}

export enum SHIFT_TRAINING_MODE {
  DISABLED,
  ENABLED,
}

export enum SHIFT_APPLICATION_STATUS {
  PENDING,
  ACCEPTED,
  INVALID, //don't use
  CANCELLED,
}

export const SHIFT_MIN_HOURLY_WAGE = 8.7
export const SHIFT_MAX_HOURLY_WAGE = 35

export const mappedPunchCardReasonsForDelete = ['No-call, No-show', 'Duplicate punch card', 'Other']

export const timesheetStatuses = ['Pending', 'Processed', 'Declined', 'Ongoing']

export const invoiceStatuses = ['In Review', 'Processed', 'Declined', 'Active']

export enum PUNCHCARD_UPDATE_ACTION {
  STANDARD,
  BYPASS_MODAL,
  PRE_FILL_MODAL,
}

export enum PUNCHCARD_REASON_MESSAGE {
  REQUEST_BY_BUSINESS = 'Business',
  REQUEST_BY_UPSHIFTER = 'Upshifter',
}

export enum PUNCHCARD_REASON_HOW_MESSAGE {
  EMAIL = 'Email',
  TEXT_MESSAGE = 'Text message',
  INTERCOM = 'Intercom',
  CALL = 'Call',
  TIMESHEET = 'Timesheet',
  FORGOT_TO_CLOCK_IN_AND_OUT = 'Forgot to clock in and out',
  FORGOT_A_SHORT_BREAK = 'Forgot a short break',
  TOOK_A_LONGER_BREAK = 'Took a longer break',
  WAS_ASKED_TO_STAY_LATER = 'Was asked to stay later',
  WAS_ASKED_TO_ARRIVE_EARLIER = 'Was asked to arrive earlier',
  REASON_UPDATE_OTHER = 'Other',
}

export const punchCardEditReasons = ['Business', 'Upshifter', 'Manual business', 'Manual labor']
// MANUAL_BUSINESS_CREATE on reason_for_create is 3 on BND
export const punchCardCreateReasons = ['Business', 'Upshifter', '', 'Manual business']

export const punchCardEditHowReasons = [
  'Email',
  'Text message',
  'Intercom',
  'Call',
  'Timesheet',
  'Forgot to clock in and out',
  'Took a short break',
  'Took a longer break',
  'Was asked to stay later',
  'Was asked to arrive earlier',
  'Other',
]
export const punchCardCreateHowReasons = [
  'Email',
  'Text message',
  'Intercom',
  'Call',
  'Timesheet',
  'Forgot to clock in and out',
  'Took a short break',
  'Took a longer break',
  'Was asked to stay later',
  'Was asked to arrive earlier',
  'Other',
]

export const sickLeaveAssignmentFrequency = ['Weekly', 'Monthly', 'Quarterly']

export const sickLeaveRate = [
  'Minimum wage',
  'Pay rate of last worked shift',
  'Average of worked shifts in past X months',
]

export const sickLeaveAccrualStart = [
  'Date of hire',
  'Days after first worked shift',
  'Minimum hours worked',
]

// Groups

export enum GROUP_USER_STATUS {
  MEMBER,
  CREATOR,
  PENDING,
}

export enum GROUP_INVITE_TYPE {
  MOBILE_PHONE,
  EMAIL,
}

export enum BUSINESS_USER_STATUS {
  SUSPENDED = 0,
  ACTIVE = 2,
}

export enum ADMIN_USER_STATUS {
  SUSPENDED = 0,
  ACTIVE = 2,
}

const isDevelopmentEnv = !NODE_ENV || NODE_ENV === 'development' || NODE_ENV === 'dev'

export enum USER_ROLES {
  BUSINESS = 1,
  UPSHIFTER = 2,
  ADMIN = 3,
  MANAGER = isDevelopmentEnv ? 4 : 5,
  ONBOARDER = isDevelopmentEnv ? 5 : 4,
}

export enum BUSINESS_USER_ROLES {
  ALL_MANAGERS = -1,
  MANAGER = 0,
  APPROVER = 1,
  VIEWER = 2,
  BUSINESS_ADMIN = 3,
  FINANCE_MANAGER = 4,
}

export const UPSHIFTER_ROLES: USER_ROLE[] = [USER_ROLE.UPSHIFTER, USER_ROLE.LABOR]

//ADMIN ROLES ROLE IDS
export const ADMIN_ROLE_ALL_ID = -1
export const ADMIN_ROLE_ADMIN_ID = 3
export const ADMIN_ROLE_ONBOARDER_ID = 4
export const ADMIN_ROLE_SALES_AGENT_ID = 8
export const ADMIN_ROLE_AM_LEVEL_1_ID = 9
export const ADMIN_ROLE_AM_LEVEL_2_ID = 10

export const ADMIN_USER_ROLES_MAPPED = [
  {
    value: ADMIN_ROLE_ONBOARDER_ID,
    textValue: 'onboarder',
    label: 'Onboarder',
  },
  {
    value: ADMIN_ROLE_SALES_AGENT_ID,
    textValue: 'sales',
    label: 'Sales',
  },
  {
    value: ADMIN_ROLE_AM_LEVEL_2_ID,
    textValue: 'account_manager_level_two',
    label: 'Account Manager Level 2',
  },
  {
    value: ADMIN_ROLE_AM_LEVEL_1_ID,
    textValue: 'account_manager_level_one',
    label: 'Account Manager Level 1',
  },
  {
    value: ADMIN_ROLE_ADMIN_ID,
    textValue: 'admin',
    label: 'Super Admin',
  },
]
// Onboarder Documents

export enum ONBOARDER_DOCUMENT_CATEGORY {
  CATEGORY_A,
  CATEGORY_B,
  CATEGORY_C,
  CATEGORY_B_PLUS_C,
}

export const mappedStrikeSources = ['Other', 'Automated', 'Email', 'Phone', 'Intercom', 'Block']

// Aging Report

export const mappedDueDateStatuses = [
  'Current',
  '1-30 days past due',
  '31-60 days past due',
  '61-90 days past due',
  '91 and more days past due',
]

export enum AGING_REPORT_OVERDUE {
  CURRENT,
  PAST,
}

export const mappedOverdueStatuses = ['Current', 'Past Due']

// Upshifter view
export const UPSHIFTER_CURRENT_TAB = {
  MESSENGER: 'Messenger',
  INFO: 'Info',
  SHIFTS: 'Shifts',
  STRIKES: 'Strikes',
  BLOCKS: 'Blocks',
  BADGES: 'Badges',
  REVIEWS: 'Reviews',
  PROFILE_ATTACHMENTS: 'Profile Attachments',
  FAVORITE_UPSHIFTER: 'Favorite Upshifter',
  SICK_PAY_HOURS: 'Sick Pay Hours',
  DEDUCTIONS: 'Deductions',
  VACCINATIONS: 'Vaccinations',
  EXPERIENCE: 'Experience',
  WORK_HISTORY: 'Work History',
}

export const GOOGLE_MAP_API_KEY = 'AIzaSyAFIQetlXLSWy37g7FPAOoi8zvhQtu3cTM'

const CVETANKA_STANOEVA_EMAIL = 'cvetanka@upshiftwork.com'
const GAELEN_MC_COTTER_EMAIL = 'gaelen@upshift.work'
const ALEX_PANTICH_EMAIL = 'alex@upshift.work'

export const SuperSecretAllowedUsers = [
  CVETANKA_STANOEVA_EMAIL,
  GAELEN_MC_COTTER_EMAIL,
  ALEX_PANTICH_EMAIL,
]

export const LatLngDefaultMapSetting = {
  lat: 39.194,
  lng: -84.665,
}
// DOCUMENT TYPES
export enum DOCUMENT_TYPE {
  ALL = -1,
  BUSINESS_DOCUMENT = 1,
  UPSHIFTER_DOCUMENT = 2,
}

// LOCATION ACCEPTED FILES
export const LOCATION_ACCEPTED_FILE_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
]

// ONBOARDING WORKFLOWS ACCEPTED FILES
export const ONBOARDING_WORKFLOWS_ACCEPTED_FILE_TYPES = [
  'image/gif',
  'image/png',
  'image/jpg',
  'image/jpeg',
  'video/mp4',
]

//GAMIFICATION SYSTEM ENTITY NAMES
export enum GAMIFICATION_ENTITY_NAME {
  CHALLENGE = 'Challenge',
  ACHIEVEMENT = 'Achievement',
  ACHIEVEMENT_LEVEL = 'Level',
}

export enum TEMPLATE_SCREEN {
  TIME_AND_PAY,
  DETAILS,
}

export const DEFAULT_SURFACE_CURVATURE = '10px'

export const DEFAULT_TABS_WRAPPER_PADDING = '10px'

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const BONUS_CATEGORY_OPTIONS = [
  {
    value: BONUS_CATEGORY.ADDITIONAL_PAY_OWNED,
    label: 'Additional pay owned',
  },
  {
    value: BONUS_CATEGORY.DISCRETIONAL,
    label: 'Discretional',
  },
  {
    value: BONUS_CATEGORY.PRODUCTION,
    label: 'Production',
  },
]

export enum REPORTS_FILTER_TYPE {
  WEEK,
  QUARTER,
  MONTH,
  YEAR,
}

export enum DATE_FORMAT_STRING {
  MM_dd_yyyy = 'MM/dd/yyyy',
  yyyy_MM_dd = 'yyyy-MM-dd',
  MMM_dd = 'MMM dd',
  DAY_OF_WEEK_SHORT = 'E',
  DAY_OF_MONTH = 'dd',
  DAY_OF_WEEK = 'EEEE',
  E_MMMM_do_yyyy = 'E, MMMM do yyyy',
  do_MMMM_yyyy = 'do MMMM yyyy',
  MMMM_yyyy = 'MMMM yyyy',
  MM_dd_yy = 'MM/dd/yy',
  yyyy_MM_dd2 = 'yyyy/MM/dd',
  E_MMMM_do = 'E, MMMM do',
  y = 'y',
}

export enum TIME_FORMAT_STRING {
  h_mm_aaa = 'h:mm aaa',
  h_mm_aaa2 = 'h:mmaaa',
  hh_mm_a = 'hh:mm a',
}

export enum DATE_AND_TIME_FORMAT_STRING {
  MM_dd_yyyy_hh_mm_a = 'MM/dd/yyyy hh:mm a',
  MMM_d_yyyy_h_mm_a = 'MMM d, yyyy h:mm a',
  MM_dd_yyyy_hh_mm_ss = 'MM/dd/yyyy HH:mm:ss',
}

export const DEFAULT_GEOFENCING_RADIUS = 0.25
export const GEOFENCING_RADIUS_MIN = 0.1
export const GEOFENCING_RADIUS_MAX = 1.5
export const GEOFENCING_RADIUS_STEP = 0.25

export const USER_LOCATION_DISTANCE_MIN = 1
export const USER_LOCATION_DISTANCE_MAX = 50

export const TWO_DIGITS_DECIMAL_NUMBER = /^(\d+(\.\d{1,2})?)?$/

export const enum APPLICATIONS_TYPE {
  INDIVIDUAL = 'Individual',
  GROUPS = 'Groups',
}
export const MAXIMUM_GROUP_MEMBERS = 3

export enum ACHIEVEMENT_LEVELS_ORDER_DIRECTION {
  UP = 'UP',
  DOWN = 'DOWN',
}

export enum ACHIEVEMENT_LEVEL_ACTION {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
}

export enum GAMIFICATION_TAB {
  CHALLENGES = 'Challenges',
  ACHIEVEMENTS = 'Achievements',
}

export const SEND_BLAST_NOTIFICATIONS_ALLOWED_USERS = [
  'cvetanka@upshiftwork.com',
  'alex@upshift.work',
  'alex@upshiftwork.com',
  'christy@upshiftwork.com',
  'utku@upshiftwork.com',
  'milena@upshiftwork.com',
  'harrison@goupshift.com',
  'ben@upshiftwork.com',
  'zach.tatoian@upshiftwork.com',
  'michael.hundley@upshiftwork.com',
  'azra@goupshift.com',
]

export enum VMS_PUNCH_CARD_STATUS {
  UNSUBMITTED,
  SUBMITTED,
}

export enum OPACITY_VALUES {
  FULL = 1,
  MEDIUM = 0.5,
}

export const DELETE_PENDING_UPSHIFTERS_ALLOWED_USERS = [
  'alex@upshiftwork.com',
  'teertsah@upshiftwork.com',
  'josh@upshiftwork.com',
  'leslie@upshiftwork.com',
  'cvetanka@upshiftwork.com',
  'bojan@upshiftwork.com',
  'ana.markovska@upshiftwork.com',
  'ana.stojchevska@upshiftwork.com',
  'a.na.marko.v.s.ka@upshiftwork.com',
  'vladoadmin@upshift.work',
  'kamelija@upshiftwork.com',
]

export enum NOTIFICATION_PREFERENCES_TAB {
  ADMIN_AND_BUSINESS = 'Admin & Business',
  ONLY_ADMIN = 'Only Admin',
}

export enum VMS_TAB {
  ONBOARDING = 'Onboarding Management',
  INVOICING = 'Invoicing',
}

export enum DETAILED_UPSHIFTER_MODAL_TAB {
  MESSENGER = 'Messenger',
  CERTIFICATES = 'Certificates',
  EXPERIENCE = 'Experience',
  BIO = 'Bio',
  SHIFTS = 'Shifts',
  STRIKES = 'Strikes',
  WORK_HISTORY = 'Work History',
}
