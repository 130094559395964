import { ActivityLogsApi } from './ActivityLog'
import { TemplatesApi } from './Template/index'
import { PropertyCategoriesApi } from './PropertyCategory'
import { CertificatesApi } from './Certificate'
import { CertificateOrganizationsApi } from './CertificateOrganization'
import { UserCertificatesApi } from './UserCertificate'
import { AuthApi } from './Auth'
import { AxiosService } from './config/config'
import { NotesApi } from './Note'
import { BusinessEntitiesApi } from './BusinessEntity'
import { InvoicesApi } from './Invoice'
import { ReportsApi } from './Report'
import { AttachmentsApi } from './Attachment'
import { DocumentsApi } from './Document'
import { AcknowledgedDocumentsApi } from './AcknowledgedDocument'
import { UsersApi } from './User'
import { NetOptionsApi } from './NetOption'
import { StateApi } from './State'
import { IndustriesApi } from './Industry'
import { PunchCardsApi } from './PunchCard'
import { RegionApi } from './Region'
import { FavoritesApi } from './Favorite'
import { TimesheetsApi } from './Timesheet'
import { ShiftsApi } from './Shift'
import { CitiesApi } from './City'
import { CountriesApi } from './Country'
import { CountiesApi } from './County'
import { MinimumWagesApi } from './MinimumWage'
import { AccruedSickHoursApi } from './AccruedSickHour'
import { SickLeaveTemplatesApi } from './SickLeaveTemplate'
import { AttachmentTitlesApi } from './AttachmentTitle'
import { InvoiceGroupsApi } from './InvoiceGroup'
import { CreditsApi } from './Credit'
import { ExpenseTypesApi } from './ExpenseType'
import { PropertiesApi } from './Property'
import { InvoiceExpenseTypesApi } from './InvoiceExpenseType'
import { ApplicationsApi } from './Applications'
import { GigDaysApi } from './GigDay'
import { UserAttachmentsApi } from './UserAttachment'
import { OnboardDocumentCategoriesApi } from './OnboardDocumentCategory'
import { OnboardDocumentsApi } from './OnboardDocument'
import { OnboardingWorkflowsApi } from './OnboardingWorkflows'
import { ReliabilityRatingsApi } from './ReliabilityRating'
import { ScreeningTypesApi } from './ScreeningType'
import { DeductionTypesApi } from './DeductionType'
import { NotificationsApi } from './Notifications'
import { BlocksApi } from './Block'
import { BackgroundChecksApi } from './BackgroundCheck'
import { StrikeTypesApi } from './StrikeType'
import { StrikesApi } from './Strike'
import { ReviewsApi } from './Review'
import { DeductionsApi } from './Deduction'
import { ScreeningsApi } from './Screening'
import { GigTypesApi } from './GigType'
import { PropertyTypesApi } from './PropertyType'
import { AutoAcceptCertificatesApi } from './AutoAcceptCertificate'
import { SuggestedPayRatesApi } from './SuggestedPayRate'
import { CreditCardsApi } from './CreditCard'
import { CreditCategoryTypesApi } from './CreditCategoryType'
import { FeaturedShiftCampaignsApi } from './FeaturedShiftCampaign'
import { ExperiencesApi } from './Experience'
import { GigAutoAcceptCertificatesApi } from './GigAutoAcceptCertificate'
import { BlastsApi } from './Blast'
import { TemplateAutoAcceptCertificatesApi } from './TemplateAutoAcceptCertificate'
import { ShortLinksApi } from './ShortLink'
import { PasswordApi } from './Password'
import { PointsOfContactApi } from './PointOfContact'
import { UserPointsOfContactApi } from './UserPointOfContact'
import { VaccinationFormsApi } from './VaccinationForm'
import { VaccinationTypesApi } from './VaccinationType'
import { BonusesApi } from './Bonuses'
import { ExpenseTypeTaxesApi } from './ExpenseTypeTax'
import { TimetrackersApi } from './Timetracker'
import { PendingVerificationPunchCardsApi } from './PendingVerificationPunchCard'
import { FollowsApi } from './Follow'
import { ShiftIdentifierPolicyApi } from './ShiftIdentifierPolicy'
import { InstructionsApi } from './Instruction'
import { NotificationPreferencesApi } from './NotificationPreference'
import { UniversalTemplatesApi } from './UniversalTemplate'
import { GroupsApi } from './Groups'
import { GroupApplicationsApi } from './GroupApplications'
import { ChallengesApi } from './Challenge'
import { PositionCategoriesApi } from './PositionCategories'
import { AchievementsApi } from './Achievement'
import { WorkerCompensationRatesApi } from './WorkersCompensationRates'
import { VendorManagementSystemsApi } from './VendorManagementSystems'
import { AchAccountsApi } from './AchAccount'
import { MagicLoginLinkApi } from './MagicLoginLink'
import { InviteApplicantsApi } from './InviteApplicants'
import { UpshifterExperiencesApi } from './UpshifterExperience'
import { BlockPropertiesApi } from './BlockProperty'
import { WebSocketDataApi } from './WebsocketData'
import { GroupMembersApi } from './GroupMember'

class Api {
  axios = new AxiosService()
  Auth = new AuthApi(this.axios)
  Shifts = new ShiftsApi(this.axios)
  Notes = new NotesApi(this.axios)
  BusinessEntities = new BusinessEntitiesApi(this.axios)
  Invoices = new InvoicesApi(this.axios)
  PunchCards = new PunchCardsApi(this.axios)
  Reports = new ReportsApi(this.axios)
  Properties = new PropertiesApi(this.axios)
  Attachments = new AttachmentsApi(this.axios)
  AttachmentTitles = new AttachmentTitlesApi(this.axios)
  Documents = new DocumentsApi(this.axios)
  AcknowledgedDocuments = new AcknowledgedDocumentsApi(this.axios)
  Users = new UsersApi(this.axios)
  NetOptions = new NetOptionsApi(this.axios)
  Cities = new CitiesApi(this.axios)
  Countries = new CountriesApi(this.axios)
  Counties = new CountiesApi(this.axios)
  States = new StateApi(this.axios)
  Industries = new IndustriesApi(this.axios)
  Regions = new RegionApi(this.axios)
  Favorites = new FavoritesApi(this.axios)
  MinimumWage = new MinimumWagesApi(this.axios)
  Timesheets = new TimesheetsApi(this.axios)
  AccruedSickHours = new AccruedSickHoursApi(this.axios)
  SickLeaveTemplates = new SickLeaveTemplatesApi(this.axios)
  InvoiceGroups = new InvoiceGroupsApi(this.axios)
  Credits = new CreditsApi(this.axios)
  ExpenseTypes = new ExpenseTypesApi(this.axios)
  InvoiceExpenseTypes = new InvoiceExpenseTypesApi(this.axios)
  Applications = new ApplicationsApi(this.axios)
  GigDays = new GigDaysApi(this.axios)
  UserAttachments = new UserAttachmentsApi(this.axios)
  Certificates = new CertificatesApi(this.axios)
  CertificateOrganizations = new CertificateOrganizationsApi(this.axios)
  UserCertificates = new UserCertificatesApi(this.axios)
  PropertyCategories = new PropertyCategoriesApi(this.axios)
  Templates = new TemplatesApi(this.axios)
  OnboardDocumentCategories = new OnboardDocumentCategoriesApi(this.axios)
  OnboardDocument = new OnboardDocumentsApi(this.axios)
  OnboardingWorkflows = new OnboardingWorkflowsApi(this.axios)
  ReliabilityRating = new ReliabilityRatingsApi(this.axios)
  ScreeningTypes = new ScreeningTypesApi(this.axios)
  Screenings = new ScreeningsApi(this.axios)
  DeductionTypes = new DeductionTypesApi(this.axios)
  Notifications = new NotificationsApi(this.axios)
  Blocks = new BlocksApi(this.axios)
  BlockProperties = new BlockPropertiesApi(this.axios)
  BackgroundChecks = new BackgroundChecksApi(this.axios)
  StrikeTypes = new StrikeTypesApi(this.axios)
  Strikes = new StrikesApi(this.axios)
  Reviews = new ReviewsApi(this.axios)
  Deductions = new DeductionsApi(this.axios)
  GigTypes = new GigTypesApi(this.axios)
  PropertyTypes = new PropertyTypesApi(this.axios)
  AutoAcceptCertificates = new AutoAcceptCertificatesApi(this.axios)
  SuggestedPayRates = new SuggestedPayRatesApi(this.axios)
  CreditCards = new CreditCardsApi(this.axios)
  CreditCategoryTypes = new CreditCategoryTypesApi(this.axios)
  FeaturedShiftCampaigns = new FeaturedShiftCampaignsApi(this.axios)
  Experiences = new ExperiencesApi(this.axios)
  GigAutoAcceptCertificates = new GigAutoAcceptCertificatesApi(this.axios)
  Blasts = new BlastsApi(this.axios)
  TemplateAutoAcceptCertificates = new TemplateAutoAcceptCertificatesApi(this.axios)
  ShortLinks = new ShortLinksApi(this.axios)
  ActivityLogs = new ActivityLogsApi(this.axios)
  Password = new PasswordApi(this.axios)
  PointsOfContact = new PointsOfContactApi(this.axios)
  UserPointsOfContact = new UserPointsOfContactApi(this.axios)
  VaccinationForms = new VaccinationFormsApi(this.axios)
  VaccinationTypes = new VaccinationTypesApi(this.axios)
  Bonuses = new BonusesApi(this.axios)
  ExpenseTypeTaxes = new ExpenseTypeTaxesApi(this.axios)
  Timetrackers = new TimetrackersApi(this.axios)
  PendingVerificationPunchCards = new PendingVerificationPunchCardsApi(this.axios)
  Follows = new FollowsApi(this.axios)
  ShiftIdentifierPolicies = new ShiftIdentifierPolicyApi(this.axios)
  Instructions = new InstructionsApi(this.axios)
  NotificationPreferences = new NotificationPreferencesApi(this.axios)
  UniversalTemplates = new UniversalTemplatesApi(this.axios)
  Groups = new GroupsApi(this.axios)
  GroupMembers = new GroupMembersApi(this.axios)
  GroupApplications = new GroupApplicationsApi(this.axios)
  Challenges = new ChallengesApi(this.axios)
  PositionCategories = new PositionCategoriesApi(this.axios)
  Achievements = new AchievementsApi(this.axios)
  WorkerCompensationRates = new WorkerCompensationRatesApi(this.axios)
  VendorManagementSystems = new VendorManagementSystemsApi(this.axios)
  AchAccounts = new AchAccountsApi(this.axios)
  MagicLoginLink = new MagicLoginLinkApi(this.axios)
  InviteApplicants = new InviteApplicantsApi(this.axios)
  UpshifterExperiences = new UpshifterExperiencesApi(this.axios)
  WebSocketData = new WebSocketDataApi(this.axios)
}

export const API = new Api()
